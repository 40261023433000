<template>
  <div  class="px-15px px-lg-25px">
    
    <div class="card">
      <div class="card-header">
          <h5 class="mb-0 h6">{{ $t('zhi-fu-li-shi') }}</h5>
      </div>
      <div class="card-body">
        <table class="table  mb-0" :class="tableData.length == 0 ? ' footable aiz-table' : ''">
          <thead>
            <tr>
              <th>#</th>
              <th>{{ $t('ri-qi') }}</th>
              <th>{{ $t('jin-e') }}</th>
              <th>{{ $t('chong-zhi-lei-xing') }}</th>
              <th data-breakpoints="lg">{{ $t('zhuang-tai') }}</th>
            </tr>
          </thead>
          <tbody>
            <tr v-for="(item, i) in tableData" :key="i">
              <td>
                  {{ i + 1 }}
              </td>
              <td>{{ item.CreatedAt }}</td>
              <td>{{ parseFloat(item.Amount).toFixed(2) }}</td>
              <td>
                {{item.CoinType == 1 ? $t('User balance') : $t('bao-zheng-jin')}}
              </td>
              <td> 
                <span class=" badge badge-inline badge-success" v-if="item.Status == 1">{{ $t('yi-tong-guo-0') }}</span>
                <span class=" badge badge-inline badge-danger" v-if="item.Status == 2">{{ $t('yi-ju-jue-0') }}</span>
                <span class=" badge badge-inline badge-info" v-if="item.Status == 0">{{ $t('shen-he-zhong') }}</span>
              </td>
            </tr>
            <tr class="footable-empty" v-if="tableData.length == 0"><td colspan="5">{{ $t('shi-mo-du-mei-zhao-dao') }}</td></tr>
          </tbody>
        </table>
        <div class="aiz-pagination mt-2" v-if="page.total > 1">
          <pageBar :totalPage="page.total" :page="page.page" @change="changePage"></pageBar>
        </div>
      </div>
    </div>

  </div>
</template>
<script>
import pageBar from '@/components/page'
import { resetPrice } from '@/utils/common'
import { rechargeLog, } from '@/api/user'
export default {
  components: {
    pageBar
  },
  data() {
    return {
      hasPackage: true,
      packageUrl: require('../../../assets/imgs/package.png'),
      tableData: [],
      page: {
        page: 1,
        limit: 10,
        total: 1
      }
    }
  },
  mounted(){
    this.init()
  },
  methods: {
    resetPrice,
    changePage(page) {
      this.page.page = page
      this.init()
    },
    init() {
      let form = new FormData()
      form.append('current', this.page.page)
      form.append('pageSize', this.page.limit)
      rechargeLog(form).then(res => {
        this.tableData = res.data.Items
        this.page.total = res.data.Pagination ? res.data.Pagination.totalPage : 1
      })
    }
  }
}
</script>